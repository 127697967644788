import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom'
import Footer from '../components/Footer';
import Header from '../components/Header';
import MyAccountMenu from './MyAccountMenu';
const Invoice = () => {


    return (
        <div>
           {/* <div style={{ width: '100vw' }}>
                <Header /></div> */}

            <section id="product-listing-sec" className="inner-page-bg mb-5">
                <div className="container">
                    <div className="row">
                    <MyAccountMenu/>
                        <div className="col-lg-9  col-sm-12  Myitems-box" style={{ alignSelf: 'flex-start', marginTop: '18px' }}>
                            <div className="row">

                                <h2 className="myItems">Invoice Details</h2>
                                {/* ITEM 1 */}

                               
                            </div>
                       
                       
                           
                       
                        </div>
                    
                    
                    





                    </div>


                 



                </div>
            </section>



            <Footer />
        </div>

    )
}
export default Invoice;